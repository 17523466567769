import React, { FC } from 'react';
import classNames from 'classnames';
import { parseBoolean } from '../../../utils/parseHelpers';

import { IHTag } from './models';
import './HTag.scss';

const HTag: FC<IHTag> = ({
  underline,
  regularText,
  boldText,
  size,
  swapTitleFont,
  newline,
  centered,
}) => {
  const Tag = size ? (`h${size}` as keyof JSX.IntrinsicElements) : 'strong';
  const classes = classNames('dt-htag', {
    'dt-htag--underline': underline,
    'dt-htag--centered': centered,
  });

  const boldTextComponent = boldText ? (
    <span className="dt-htag__sub" key={boldText}>
      {boldText}
    </span>
  ) : null;

  const textComponents = parseBoolean(swapTitleFont)
    ? [boldTextComponent, regularText]
    : [regularText, boldTextComponent];

  return (
    <Tag className={classes}>
      {textComponents[0]}
      &nbsp;
      {newline ? <br /> : null}
      {textComponents[1]}
    </Tag>
  );
};

export default HTag;
