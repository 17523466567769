import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';

import HTag from 'components/common/HTag';
import PopularProductItem from 'components/common/PopularProductItem';
import TipsItem from './TipsItem';

import { ITips } from './models';

import './Tips.scss';

const Tips: FC<ITips> = ({ title: { regularText, boldText }, data, btn, popularProductData }) => {
  const classes = classNames('dt-tips', {
    'dt-tips--promo': popularProductData,
  });

  return (
    <div className={classes}>
      <div className="dt-tips__content">
        <div className="dt-tips__tips-frame">
          <HTag size={2} regularText={regularText} boldText={boldText} />
          {data?.length ? (
            <ul className="dt-tips__list">
              {data.map((item) => (
                <TipsItem key={item.name} {...item} />
              ))}
            </ul>
          ) : null}
        </div>

        {btn?.btnText && (
          <Button href={btn?.btnLink} variant="outline-primary">
            {btn?.btnText}
          </Button>
        )}

        {popularProductData ? (
          <div className="dt-tips__promo-block">
            <strong className="dt-tips__promo-title">{popularProductData.categoryTitle}</strong>
            <PopularProductItem product={popularProductData as ProductTypes.IProduct} isPromo />
            <Button variant="outline-primary" href={btn?.btnLink}>
              {btn?.btnText}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Tips;
