import React, { FC } from 'react';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';

import HTag from 'components/common/HTag';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UmbracoImage from 'components/common/Image/UmbracoImage';

import { IBanner } from './models';
import './Banner.scss';

const Banner: FC<IBanner> = ({
  swapTitleFont,
  title: { regularText, boldText },
  subTitle,
  img,
  text,
  textWrapElement = 'p',
  large,
  alt,
  sectionLandmark,
  newline,
}) => {
  const subTitleItem = subTitle ? (
    <strong className="dt-banner__sub-title">{subTitle}</strong>
  ) : null;

  const imgItem = img ? (
    <div className="dt-banner__img-holder">
      <UmbracoImage lazyLoad={false} image={img} alt={alt || ''} />
    </div>
  ) : null;

  const classes = classNames('dt-banner', 'dt-container-wrapper', {
    'dt-banner--large': large,
  });

  return (
    <section className={classes} aria-label={sectionLandmark}>
      {imgItem}

      <Container fluid className="dt-banner__content">
        {subTitleItem}
        <HTag
          size={1}
          newline={newline}
          regularText={regularText}
          boldText={boldText}
          swapTitleFont={swapTitleFont}
        />
        <DangerouslySetInnerHtml
          className="dt-banner__text"
          element={textWrapElement}
          html={text}
        />
      </Container>
    </section>
  );
};

export default Banner;
