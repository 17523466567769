import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import IconCustom from 'components/common/IconCustom';
import HTag from 'components/common/HTag';
import Image from 'components/common/Image';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import { IPopularProductItem } from './models';
import './PopularProductItem.scss';

const PopularProductItem: FC<IPopularProductItem> = ({ product, isPromo }) => {
  const classes = classNames('dt-pop-product-item', {
    'dt-pop-product-item--promo': isPromo,
  });
  const {
    shortNameRegular,
    shortNameBold,
    recognisableFeature,
    name,
    link,
    localHeroImage,
    cardImage,
    productColor,
  } = product;

  return (
    <div className={classes}>
      <Link to={link} aria-label={name}>
        <div className={`dt-pop-product-item__holder ${productColor || ''}`}>
          <UmbracoImage alt={name} image={cardImage} className="dt-umbraco-image__bg" />
          <HTag regularText={shortNameRegular} boldText={shortNameBold} />
          <div className="dt-pop-product-item__tick">
            <IconCustom icon="tick_white_icon" />
            <span>{recognisableFeature}</span>
          </div>
          <div className="dt-pop-product-item__img-holder">
            {localHeroImage && (
              <Image localImage={localHeroImage} alt={name} className="dt-pop-product-item__img" />
            )}
          </div>
        </div>
        <span className="accessibility-hidden">{name}</span>
      </Link>
    </div>
  );
};

export default PopularProductItem;
