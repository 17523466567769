import React, { FC } from 'react';
import loadable from '@loadable/component';
import { TDidYouKnowLoadableWrapperComponent } from './models';

const DidYouKnowLoadableWrapper: FC<TDidYouKnowLoadableWrapperComponent> = ({
  didYouKnow,
  ...restProps
}) => {
  if (!didYouKnow) {
    return null;
  }

  const FooterPromo = loadable(() => import('components/FooterPromo'));

  return <FooterPromo didYouKnow={didYouKnow} {...restProps} />;
};

export default DidYouKnowLoadableWrapper;
