import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import IconCustom from 'components/common/IconCustom';
import { ITipsItem } from './models';

const TipsItem: FC<ITipsItem> = ({ name, title, icon, link }) => {
  const pathname = typeof window === 'undefined' ? '' : window.location.pathname;
  const querySearch = typeof window === 'undefined' ? '' : window.location.search;
  const queryParams = new URLSearchParams(querySearch).get('tipTags');
  const tipsLink = Array.isArray(link) ? `${link[0]?.url}${link[0]?.queryString}` : link;
  const decodedLink = decodeURIComponent(tipsLink);

  const classes = classNames({
    active:
      `${pathname}?tipTags=${queryParams}` === decodedLink ||
      (decodedLink.split('tipTags=')[1] &&
        decodedLink.split('tipTags=')[1].split(',').includes(queryParams)),
  });

  return (
    <li>
      <Link to={tipsLink} className={classes}>
        <span className="dt-tips__list-icon">
          <IconCustom icon={icon} />
        </span>
        <span className="dt-tips__list-title">{title || name}</span>
      </Link>
    </li>
  );
};

export default TipsItem;
